
import { invoiceLogNames } from '@/shared/config/invoices';
import { parseDate } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
@Component({})
export default class InvoiceLogRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;
    @Prop(Boolean) public isActive!: boolean;

    public parseDate = parseDate;

    public itemName(itemName: string) {
        return invoiceLogNames.find((obj) => obj.id === itemName);
    }
}
